:root {
    --dark-90: rgba(18,47,59, 0.95)
}

select.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
select.custom-select::-ms-expand {
	display: none;
}

.custom-control-label { padding-top:0.1rem }

table th { font-weight: 500}

// Buttons =====================================================================

.btn {
  &-secondary,
  &-secondary:hover,
  &-warning,
  &-warning:hover {
    color: #fff;
  }
  &-outline {
    &-secondary:hover,  
    &-warning:hover {
      color: #fff;
    }
  }
  &-group-xs > &, 
  &-xs {
    padding  : .3rem .4rem;
    font-size  : .65rem;
    line-height  : .5rem;
    border-radius : .2rem;
  }
  cursor: pointer
}

.badge {
  &-secondary,
  &-warning {
    color: #fff;
  }
  &-label { padding: .3rem .4rem;}
}


.alert {
  border: none;
  color: $white;

  a,
  .alert-link {
    color: #fff;
    text-decoration: underline;
  }

  @each $color, $value in $theme-colors {
    &-#{$color} {
      background-color: $value;
    }
  }

  &-light {
    &,
    & a,
    & .alert-link {
      color: $body-color;
    }
  }
}